<script lang='ts'>
  import { onMount } from 'svelte'
  import { navigate } from 'svelte-routing'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { notifier } from '@/helpers/notifier'

  import { UnknownRecord, UserSettings } from '@/definitions/langoid'
  import { userStorredConfig } from '@/store'

  let userSettings: UserSettings = {
    disability: {
      desc: '',
      type: 'none'
    },
    email: {
      corrections: 1,
      friendRequests: 1,
      learningReminders: 0,
      taskComments: 0
    },
    interface: { theme: 'light' },
    mix: { audio: { chat: 0 } },
    privacy: {
      age: 1,
      inSearch: { shown: 1 },
      learningProgress: 1,
      location: 1
    },
    tests: {
      algorithm: {},
      disableAudioTestType: 0,
      mode: 'basic',
      showInputField: 1,
      showTimer: 0,
      skipCorrect: 1
    }
  }

  // Calculate an initial value for form

  const createForm = () => {
    form = {
      disability: {
        desc: userSettings.disability.desc,
        type: userSettings.disability.type
      },
      email: {
        corrections: userSettings.email.corrections === 1,
        friendRequests: userSettings.email.friendRequests === 1,
        learningReminders: userSettings.email.learningReminders === 1,
        taskComments: userSettings.email.taskComments === 1
      },
      // Add rest of the form fields here...
      interface: {
        theme: userSettings.interface.theme
      },
      mix: {
        audio: {
          chat: userSettings.mix.audio.chat === 1
        }
      },
      privacy: {
        age: userSettings.privacy.age === 1,
        inSearch: {
          shown: userSettings.privacy.inSearch.shown === 1
        },
        learningProgress: userSettings.privacy.learningProgress === 1,
        location: userSettings.privacy.location === 1
      },
      tests: {
        algorithm: userSettings.tests.algorithm,
        disableAudioTestType: userSettings.tests.disableAudioTestType === 1,
        mode: userSettings.tests.mode,
        showInputField: userSettings.tests.showInputField === 1,
        showTimer: userSettings.tests.showTimer === 1,
        skipCorrect: userSettings.tests.skipCorrect === 1
      }
    }
  }
  let form: UnknownRecord

  onMount(async () => {
    const data: { settings: UserSettings } = await fetchData('social/loadUserSettings')
    userSettings = data.settings
    document.body.setAttribute('data-theme', data.settings.interface.theme)
    localStorage.setItem('theme', data.settings.interface.theme)
    createForm()
  })

  async function save () {
    // Before saving, map the booleans back into numbers
    userSettings.email.corrections = form.email.corrections ? 1 : 0
    userSettings.email.friendRequests = form.email.friendRequests ? 1 : 0
    userSettings.email.learningReminders = form.email.learningReminders ? 1 : 0
    userSettings.email.taskComments = form.email.taskComments ? 1 : 0
    userSettings.mix.audio.chat = form.mix.audio.chat ? 1 : 0
    userSettings.tests.disableAudioTestType = form.tests.disableAudioTestType ? 1 : 0
    userSettings.tests.showInputField = form.tests.showInputField ? 1 : 0
    userSettings.tests.showTimer = form.tests.showTimer ? 1 : 0
    userSettings.tests.skipCorrect = form.tests.skipCorrect ? 1 : 0
    userSettings.privacy.location = form.privacy.location ? 1 : 0
    userSettings.privacy.age = form.privacy.age ? 1 : 0
    userSettings.privacy.inSearch.shown = form.privacy.inSearch.shown ? 1 : 0
    userSettings.privacy.learningProgress = form.privacy.learningProgress ? 1 : 0
    await fetchData('social/saveUserSettings', { settings: userSettings })

    // set dark mode
    document.body.setAttribute('data-theme', userSettings.interface.theme)
    localStorage.setItem('theme', userSettings.interface.theme)
    notifier.info('Your settings are saved') // TODO: i18n
  }

  function profile () {
    navigate(`/profile/${$userStorredConfig.id}`)
  }

</script>
{#if form}
  <div class='_gap24'>
    <h2>{$_('settings.editSettings')}</h2>
    <hr />
    <form class='_form' on:submit|preventDefault={save}>
      <div>
        <h3>{$_('auth.email')}</h3>
        <p>
          <label class='check-button'>
            <input type='checkbox' bind:checked={form.email.corrections} />
            <span class='checkbox' />
            {$_('mix.corrections')}
          </label>
          <label class='check-button not-enabled'>
            <input type='checkbox' bind:checked={form.email.friendRequests} />
            <span class='checkbox' />
            {$_('settings.friendRequests')}
          </label>
          <label class='check-button not-enabled'>
            <input type='checkbox' bind:checked={form.email.learningReminders} />
            <span class='checkbox' />
            {$_('settings.learningReminders')}
          </label>
          <label class='check-button not-enabled'>
            <input type='checkbox' bind:checked={form.email.taskComments} />
            <span class='checkbox' />
            {$_('tasksPage.comments')}
          </label>
        </p>
      </div>
      <div class='not-enabled'>
        <h3>{$_('settings.privacy')}</h3>
        <p>
          <label class='check-button'>
            <input type='checkbox' bind:checked={form.privacy.location} />
            <span class='checkbox' />
            {$_('settings.location')}
          </label>
          <!-- Add more checkbox for other Privacy fields here -->
          <label class='check-button'>
            <input type='checkbox' bind:checked={form.privacy.age} />
            <span class='checkbox' />
            {$_('settings.age')}
          </label>
          <label class='check-button'>
            <input type='checkbox' bind:checked={form.privacy.inSearch.shown} />
            <span class='checkbox' />
            {$_('settings.inSearch')}
          </label>
          <label class='check-button'>
            <input type='checkbox' bind:checked={form.privacy.learningProgress} />
            <span class='checkbox' />
            {$_('settings.learningProgress')}
          </label>
        </p>
      </div>
      <!-- Add more sections for other categories
         (interface, disability, tests, mix)
         similar to Email and Privacy above -->
      <div>
        <h3>{$_('testsPage.title')}</h3>
        <p>
          <label class='check-button'>
            <input type='checkbox' bind:checked={form.tests.showTimer} />
            <span class='checkbox' />
            {$_('settings.showTimer')}
          </label>
          <label class='check-button'>
            <input type='checkbox' bind:checked={form.tests.showInputField} />
            <span class='checkbox' />
            {$_('settings.showInputField')}
          </label>
          <label class='check-button'>
            <input type='checkbox' bind:checked={form.tests.disableAudioTestType} />
            <span class='checkbox' />
            {$_('settings.disableAudioTest')}
          </label>
        </p>
        <p>
          <label>
            {$_('settings.mode')}
            <select bind:value={userSettings.tests.mode} name=''>
              <option value='basic'>{$_('plans.basic')}</option>
              <option value='advanced'>{$_('plans.advanced')}</option>
              <option value='expert'>{$_('plans.expert')}</option>
            </select>
          </label>
        </p>
        <p>
          <label class='check-button'>
            <input type='checkbox' bind:checked={form.tests.skipCorrect} />
            <span class='checkbox' />
            {$_('settings.autoSkipCorrect')}
          </label>
        </p>
      </div>
      <div>
        <h3>{$_('settings.theme')}</h3>
        <p>
          <label>
            <select bind:value={userSettings.interface.theme} name=''>
              <option value='light'>{$_('settings.lightMode')}</option>
              <option value='dark'>{$_('settings.darkMode')}</option>
            </select>
          </label>
        </p>
        <!--
        Theme
        -->
      </div>
      <div class='not-enabled'>
        <h3>{$_('settings.mix')}</h3>
        <label>
          <input type='checkbox' bind:checked={form.mix.audio.chat} />
          {$_('mix.chat')}
        </label>
      </div>
      <div class='not-enabled'>
        <h3>{$_('settings.accessibility')}</h3>
        <label>
          {$_('settings.accessibilityType')}
          <select bind:value={userSettings.disability.type} name=''>
            <option value='none'>{$_('mix.none')}</option>
            <option value='deaf'>{$_('settings.deaf')}</option>
            <option value='blind'>{$_('settings.blind')}</option>
            <option value='colorblind'>{$_('settings.colorBlind')}</option>
            <option value='other'>{$_('mix.other')}</option>
          </select>
        </label>
        {#if userSettings.disability.type === 'other'}
          <label>
            {$_('settings.disabilityDescription')}
            <input type='text' bind:value={userSettings.disability.desc} />
          </label>
        {/if}
      </div>
      <div class='save-or-cancel'>
        <button type='submit'>{$_('mix.save')}</button>
        <button type='button' on:click={profile}>{$_('mix.cancel')}</button>
      </div>
    </form>
  </div>
{/if}
<style lang='scss'>
  .not-enabled {
    display: none;
  }

  .save-or-cancel {
    display: flex;
    gap: 0.8rem;
  }
</style>
