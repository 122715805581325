<script lang='ts'>
  import { ChartValue } from '@/definitions/langoid'

  export let values: ChartValue[] = []
  let chartEl: HTMLCanvasElement
  let chart: any
  let Chart: any
  (async () => {
    const tmp = await import('@/helpers/chart')
    if (tmp) Chart = tmp.Chart
  })()
  const style = getComputedStyle(document.body)
  const gridColor = style.getPropertyValue('--text-background')

  $: if (values && chartEl && Chart) {
    if (chart) chart.destroy() // clear the canvas
    const options: any = {
      data: {
        datasets: [
          {
            backgroundColor: values.map((i) => i.backgroundColor),
            borderWidth: 0,
            data: values.map((i) => i.value),
            label: 'Vocabulary'
          }
        ],
        labels: values.map((i) => i.label)
      },
      options: {
        legend: { position: 'right' },
        scales: {
          x: {
            grid: {
              color: gridColor
            }
          },
          y: {
            grid: {
              color: gridColor
            }
          }
        },
      },
      type: 'bar'
    }
    chart = new Chart(chartEl, options)
  }
</script>

<canvas bind:this={chartEl} height='200' width='200' />
