<script lang='ts'>
  import { link } from 'svelte-routing'

  import { MAX_LINK_LENGTH, MINUTES_PER_HOUR } from '@/config/constants'

  import { getIsoFromId } from '@/helpers/apiCall'
  import { timeAgo } from '@/helpers/dateHelpers'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { slugify, truncateUrl } from '@/helpers/mixHelpers'

  import Icon from '@/components/icons/Icon.svelte'
  import EmptyState from '@/components/ui/EmptyState.svelte'

  import { UserBookmarksApi } from '@/definitions/langoid'
  import { userStorredConfig } from '@/store'

  let activeTab = '',
    contentList: any = [],
    morePosts = [],
    offset = 0

  const iso = getIsoFromId($userStorredConfig.id_lang_learning)

  const offsetLimit: number = 20

  const mainTabs: Record<string, string> = {
    forum: $_('forum.forum'),
    history: $_('activity.userHistory'),
    tasks: $_('activity.tasks')
  }

  const tabNames: any =
    {
      forum: {
        createdReplies: $_('activity.createdReplies'),
        createdTopics: $_('activity.createdTopics'),
        createdTranslations: $_('activity.createdTranslations'),
        editedReplies: $_('activity.editedReplies'),
        editedTopics: $_('activity.editedTopics'),
        editedTranslations: $_('activity.editedTranslations'),
        likedReplies: $_('activity.likedReplies'),
        likedTopics: $_('activity.likedTopics')
      },
      history: {
        dailyTime: $_('activity.dailyTime'),
        pointsHistory: $_('activity.pointsHistory')
      },
      tasks: {
        createdComments: $_('activity.comments'),
        createdCorrections: $_('mix.corrections'),
        createdTasks: $_('activity.tasks'),
        votedComments: $_('activity.votedComments'),
        votedCorrections: $_('activity.votedCorrections'),
        votedTasks: $_('activity.votedTasks')
      }
    }

  async function loadList () {
    const data: UserBookmarksApi = await fetchData('social/myActivities', { activeTab, offset })
    contentList = data.contentList
  }

  async function loadMore () {
    offset += offsetLimit
    const data: UserBookmarksApi = await fetchData('social/myActivities', { activeTab, offset })
    morePosts = data.contentList
    contentList = [...contentList, ...morePosts]
  }

  function showHideTabsGroup (e: MouseEvent, mainTab: string) {
    const target = e.target as HTMLDivElement
    target.nextElementSibling?.classList.toggle('-hide')
    if (!target.classList.contains('-hide') && Object.keys(tabNames[mainTab]).includes(activeTab)) {
      activeTab = ''
    }
  }

  function showTabContent (tabName: string) {
    activeTab = tabName
    loadList()
  }

  function activeTime (minutes: number) {
    if (minutes < MINUTES_PER_HOUR) {
      return minutes + ' minute(s)'
    } else {
      const hour = Math.floor(minutes / MINUTES_PER_HOUR)
      const minute = minutes % MINUTES_PER_HOUR
      return hour + 'hour(s) ' + minute + ' minute(s)'
    }
  }

  const formatTasksRating = (rating: number = 0) => {
    if (rating >= 1) {
      return $_('activity.upvote')
    } else if (rating === -1) {
      return $_('activity.downvote')
    } else if (rating === 0) {
      return $_('activity.removed')
    }
    return ''
  }

  const formatForumRating = (rating: number = 0) => {
    const FORUM_LIKE_RATING = 10
    if (rating === FORUM_LIKE_RATING) {
      return $_('activity.newLike')
    } else if (rating === 0) {
      return $_('activity.removedLike')
    }
    return ''
  }
</script>

<div class='_gap24'>
  <h2>{$_('profile.myActivities')}</h2>
  <hr />
  <div class='tabs-content'>
    <div class='vertical-tabs-wrapper'>
      {#each Object.keys(mainTabs) as mainTab}
        <div
          class='vertical-tab'
          role='button'
          tabindex='0'
          on:click|stopPropagation={(e) => showHideTabsGroup(e, mainTab)}
          on:keypress={() => {}}
        >{mainTabs[mainTab]}
          <span class='arrow'>◢</span>
        </div>
        <div class='tabs-wrapper -hide'>
          {#each Object.keys(tabNames[mainTab]) as tabName}
            <div
              class='tab'
              class:-active={activeTab === tabName}
              role='button'
              tabindex='0'
              on:click={() => showTabContent(tabName)}
              on:keypress={() => {}}
            >
              {tabNames[mainTab][tabName]}
            </div>
          {/each}
        </div>
      {/each}
    </div>

    <div class='table-wrapper'>
      <table class='_table'>
        {#if activeTab || (activeTab && contentList.length === 0)}
          <thead>
          <tr>
            {#if activeTab === 'dailyTime'}
              <th>{$_('mix.date')}</th>
              <th>{$_('activity.timeSpent')}</th>
            {:else if activeTab === 'pointsHistory'}
              <th>{$_('activity.action')}</th>
              <th>{$_('mix.date')}</th>
              <th>{$_('menu.points')}</th>
            {:else if ['likedReplies', 'likedTopics', 'votedComments', 'votedCorrections', 'votedTasks'].includes(activeTab)}
              <th>{$_('activity.action')}</th>
              <th>{$_('mix.date')}</th>
              <th>{$_('mix.link')}</th>
            {:else}
              <th>{$_('mix.date')}</th>
              <th>{$_('mix.link')}</th>
            {/if}
          </tr>
          </thead>
        {/if}
        <tbody>
        {#if activeTab === ''}
          <tr>
            <td colspan='3'>
              <EmptyState src='/images/empty-file.svg'>
                <div class='empty-state'>{$_('profile.emptyMessage')}</div>
              </EmptyState>
            </td>
          </tr>
        {:else if activeTab && contentList.length === 0}
          <tr>
            <td colspan='3'>
              <EmptyState src='/images/empty-file.svg'>
                <div class='empty-state'>{$_('activity.emptyNote')}&nbsp;{$_('activity.firstActivity')} <a href='/forum'>{$_('tasksPage.modalLinkHere')}</a>.
                </div>
              </EmptyState>
            </td>
          </tr>
        {:else}
          {#each contentList as contentList}
            {#if activeTab === 'dailyTime'}
              <tr>
                <td>{contentList.logDate}</td>
                <td>{activeTime(contentList.activeTime)}</td>
              </tr>
            {:else if activeTab === 'pointsHistory'}
              <tr>
                <td>{contentList.description}</td>
                <td>{timeAgo(contentList.action_time)}</td>
                <td>{contentList.points_awarded}</td>
              </tr>
            {:else if ['likedReplies', 'likedTopics'].includes(activeTab)}
              {@const actionLink = '/' + iso + '/t/' + slugify(contentList.content) + '/' + contentList.topicId +
              (contentList.replyId ? '?reply=' + contentList.replyId : '')}
              <tr>
                <td>{formatForumRating(contentList.rating)}</td>
                <td>{timeAgo(contentList.action_time)}</td>
                <td class='tableField -left'>
                  <a href={actionLink} rel='noopener noreferrer' target='_blank' use:link>
                    {truncateUrl(contentList.content, MAX_LINK_LENGTH)}
                  </a>
                </td>
              </tr>
            {:else if ['votedComments', 'votedCorrections', 'votedTasks'].includes(activeTab)}
              {@const actionLink = '/' + contentList.iso + '/task/' + contentList.slug + '/correct/' + contentList.taskId +
              (contentList.commentId ? '?comment=' + contentList.commentId : '') +
              (contentList.correctionId ? '?correction=' + contentList.correctionId : '')}
              <tr>
                <td>{formatTasksRating(contentList.rating)}</td>
                <td>{timeAgo(contentList.action_time)}</td>
                <td class='tableField -left'>
                  <a href={actionLink} rel='noopener noreferrer' target='_blank' use:link>
                    {#if contentList.content}
                      {truncateUrl(contentList.content, MAX_LINK_LENGTH)}
                    {:else}
                      <Icon icon='Play' />
                    {/if}
                  </a>
                </td>
              </tr>
            {:else if ['createdReplies', 'createdTopics', 'createdTranslations', 'editedReplies', 'editedTopics', 'editedTranslations'].includes(activeTab)}
              {@const actionLink = '/' + iso + '/t/' + slugify(contentList.content) + '/' + contentList.topicId +
              (contentList.replyId ? '?reply=' + contentList.replyId : '')}
              <tr>
                <td>{timeAgo(contentList.action_time)}</td>
                <td class='tableField -left'>
                  <a href={actionLink} rel='noopener noreferrer' target='_blank' use:link>
                    {truncateUrl(contentList.content, MAX_LINK_LENGTH)}
                  </a>
                </td>
              </tr>
            {:else if ['createdComments', 'createdCorrections', 'createdTasks'].includes(activeTab)}
              {@const actionLink = '/' + contentList.iso + '/task/' + contentList.slug + '/correct/' + contentList.taskId +
              (contentList.commentId ? '?comment=' + contentList.commentId : '') +
              (contentList.correctionId ? '?correction=' + contentList.correctionId : '')}
              <tr>
                <td>{timeAgo(contentList.action_time)}</td>
                <td class='tableField -left'>
                  <a href={actionLink} rel='noopener noreferrer' target='_blank' use:link>
                    {#if contentList.content}
                      {truncateUrl(contentList.content, MAX_LINK_LENGTH)}
                    {:else}
                      <Icon icon='Play' />
                    {/if}
                  </a>
                </td>
              </tr>
            {/if}
          {/each}
        {/if}
        </tbody>
      </table>
      {#if contentList.length < offsetLimit || contentList.length < offset + offsetLimit || activeTab === ''}
        <button class='load-more -disabled' type='button' on:click={() => loadMore()}>Load more</button>
      {:else}
        <button class='load-more -primary' type='button' on:click={() => loadMore()}>Load more</button>
      {/if}
    </div>
  </div>
</div>
<style lang='scss'>
  .tabs-content {
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
  }

  .vertical-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 23rem;
    margin: 0.1rem 0 0.1rem 0;
    padding: 0.8rem 1rem 0.8rem 1rem;
    border-radius: 0.2rem;
    cursor: pointer;
  }

  .tabs-wrapper {
    &.-hide {
      display: none;
    }

    > .tab {
      width: 19.2rem;
      margin: 0.1rem 0 0.1rem 3.8rem;
      padding: 0.8rem 0 0.8rem 1rem;
      background: var(--Primary-Lighter);
      border-radius: 0.2rem;
      cursor: pointer;

      &.-active {
        display: block;
        margin-left: 2.8rem;
        color: var(--Base-White);
        background: var(--Primary-Medium);
      }
    }
  }

  .load-more {
    &.-disabled {
      display: none;
    }

    &.-primary {
      margin: 2rem auto;
    }
  }

  @media (max-width: 768px) {
    ._gap24 {
      flex-direction: column;
    }

    .vertical-tabs-wrapper {
      margin: 0;
    }

    .vertical-tab {
      width: 100%;
    }

    .tabs-wrapper {
      > .tab {
        width: 90%;
        margin: 0.1rem auto;
      }
    }
  }
</style>
