<script lang='ts'>
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'

  export let receivedRequest: boolean, sentRequestMessage: string, friendId: number

  const sendRequest = async () => {
    await fetchData('social/profileAddFriend', { friendId })
    sentRequestMessage = 'waiting'
  }

  const acceptRequest = async () => {
    await fetchData('social/profileAcceptFriendRequest', { friendId })
    sentRequestMessage = 'friends'
    receivedRequest = false
  }
  const rejectRequest = async () => {
    await fetchData('social/profileUnfriend', { friendId, type: 'reject' })
    sentRequestMessage = 'reject'
    receivedRequest = false
  }
  const cancelRequest = async () => {
    await fetchData('social/profileUnfriend', { friendId, type: 'cancel' })
    sentRequestMessage = 'cancel'
    receivedRequest = false
  }
  const deleteFriend = async () => {
    await fetchData('social/profileUnfriend', { friendId, type: 'delete' })
    sentRequestMessage = 'delete'
    receivedRequest = false
  }
</script>

<div class='friend-request-buttons'>
  {#if receivedRequest}
    <button class='_success-btn' type='button' on:click={acceptRequest}>
      {$_('profile.acceptFriend')}
    </button>
    <button class='_quaternary-btn' type='button' on:click={rejectRequest}>
      {$_('profile.rejectFriendRequest')}
    </button>
  {:else if sentRequestMessage === 'add_friend' || sentRequestMessage === 'waiting'}
    {#if sentRequestMessage === 'waiting'}
      <p>{$_('profile.sentFriendRequest')}</p>
      <button class='_quaternary-btn' type='button' on:click={cancelRequest}>
        {$_('profile.cancelFriendRequest')}
      </button>
    {:else}
      <button type='button' on:click={sendRequest}>
        {$_('profile.addFriend')}
      </button>
    {/if}
  {:else if sentRequestMessage === 'friends'}
    <button class='_quaternary-btn' type='button' on:click={deleteFriend}>
      {$_('profile.removeFriend')}
    </button>
  {/if}
</div>

<style lang='scss'>
  .friend-request-buttons {
    display: flex;
    gap: 0.8rem;
    align-items: center;
  }
</style>
