<script lang='ts'>
  import { _ } from '@/helpers/i18n'

  import Flag from '@/components/forum/Flag.svelte'
  import Overlay from '@/components/ui/Overlay.svelte'

  import { UserProfile } from '@/definitions/langoid'
  import EditInterfaceLanguage from '@/pages/social/EditInterfaceLanguage.svelte'
  import EditLanguagesPage from '@/pages/social/editLanguages.svelte'

  export let languages: number[] = [],
    isMyProfile: boolean = false,
    user: UserProfile = {} as UserProfile

  let editLanguages = false
  let editingInterfaceLanguage = false
</script>
<div class='user-languages'>
  <div class='profile-box _row'>
    <span class='profileBoxTitle'>{$_('profile.myNativeLanguage')}</span>
    {#key user.id_lang_native}
      <Flag id={user.id_lang_native} variant='small' />
    {/key}
  </div>

  <div class='profile-box _row'>
    <span class='profileBoxTitle'>{$_('mix.interface')}</span>
    {#if user.id_lang_interface}
      {#key user.id_lang_interface}
        <Flag id={user.id_lang_interface} variant='small' />
        {#if user.interface_variant}
          {user.interface_variant}
        {/if}
      {/key}
    {/if}
    {#if isMyProfile}
      <a
        class='edit-languages'
        href='.'
        on:click|preventDefault={() => {editingInterfaceLanguage = true}}
      >{$_('profile.editLanguages')}</a>
      {#if editingInterfaceLanguage}
        <Overlay onClick={() => {editingInterfaceLanguage = false}} variant="overflow">
          <EditInterfaceLanguage bind:editingInterfaceLanguage />
        </Overlay>
      {/if}
    {/if}
  </div>
  <div class='profile-box _row'>
    <span class='profileBoxTitle'>{$_('profile.imLearning')}</span>
    {#each languages.filter(el => el !== user.id_lang_native) as lang}
      {#key lang}
        <Flag id={lang} variant='small' />
      {/key}
    {/each}

    {#if isMyProfile}
      <a
        class='edit-languages'
        href='.'
        on:click|preventDefault={() => {editLanguages = true}}
      >{$_('profile.editLanguages')}</a>
      {#if editLanguages}
        <Overlay onClick={() => {editLanguages = false}}>
          <EditLanguagesPage bind:profileLanguages={languages} bind:editLanguages />
        </Overlay>
      {/if}
    {/if}
  </div>
</div>
<style lang='scss'>
  .user-languages {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  .profile-box {
    position: relative;

    > .profileBoxTitle {
      display: inline-block;
      min-width: 7.2rem;
      font: var(--Regular-400);
      color: var(--text-primary-color);
    }
  }

  .edit-languages {
    position: relative;
    top: -0.2rem;
    text-decoration: underline;
  }
</style>
