<script lang='ts'>
  import { _ } from '@/helpers/i18n'
  import { navigateWithReset } from '@/helpers/navigateWithReset'

  import CloseButton from '@/components/ui/CloseButton.svelte'

  import UserMainInfo from './UserMainInfo.svelte'

  import { SearchUser } from '@/definitions/langoid'

  export let user: SearchUser, close: () => void
</script>

<div class='user-profile'>
  <CloseButton closeVariant='absolute' onClick={close} />
  <a
    class='viewProfileButton'
    href='/profile/{user.id}'
    on:click|preventDefault={() => {
    navigateWithReset(`/profile/${user.id}`)
    close()
  }}
  >{$_('social.viewProfile')}</a>
  <UserMainInfo {user} />
</div>

<style lang='scss'>
  .user-profile {
    background: var(--main-background);

    > .viewProfileButton {
      &:visited {
        color: var(--Primary-Medium);
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
</style>
