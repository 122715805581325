<script lang='ts'>
  import { link } from 'svelte-routing'

  import { HOURS_PER_DAY, MINUTES_PER_HOUR } from '@/config/constants'

  import { _ } from '@/helpers/i18n'

  import { TimeStats } from '@/definitions/langoid'

  export let stats: TimeStats
  export let isMyProfile: boolean = false

  // format long or short, short means only hours and minutes
  function minutesFormat (minutes: number, format = 'long'): string {
    const minutesPerDay = HOURS_PER_DAY * MINUTES_PER_HOUR
    if (!minutes) return ''
    if (minutes > minutesPerDay && format === 'long') {
      return Math.floor(minutes / minutesPerDay) + $_('mix.d') + ' ' +
        Math.floor((minutes % minutesPerDay) / MINUTES_PER_HOUR) + $_('mix.h') + ' ' + (minutes % MINUTES_PER_HOUR) + $_('mix.m')
    }
    if (minutes > MINUTES_PER_HOUR) return Math.floor(minutes / MINUTES_PER_HOUR) + $_('mix.h') + ' ' + (minutes % MINUTES_PER_HOUR) + $_('mix.m')
    return minutes + $_('mix.minutes')
  }
</script>

<div class='stats-section'>
  <div class='time-stats'>
    <span class='statsText'>{$_('profile.statsLastMonth')} </span>
    <span class='statsTime'>{minutesFormat(stats.lastMonth, 'short') || '-'}</span>
  </div>
  <div class='time-stats'>
    <span class='statsText'>{$_('profile.statsFromBeginning')} </span>
    <span class='statsTime'>{minutesFormat(stats.total, 'short') || '-'}</span>
  </div>
  <div class='time-stats'>
    <span class='statsText'>{$_('profile.statsDays')} </span>
    <span class='statsTime'>{stats.activeDays || '-'}</span>
  </div>
  {#if isMyProfile}
    <a class='more-info' href='/my-activities' use:link><span class='link'>{$_('profile.myActivities')}</span><span class='arrow'>→</span></a>
  {/if}
</div>

<style lang='scss'>
  .stats-section {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  .time-stats {
    display: flex;
    gap: 1rem;

    > .statsText {
      font-size: 1.2rem;
    }

    > .statsTime {
      font-weight: 700;
      font-size: 1rem;
    }

    &:nth-child(even) {
      background: var(--main-background);
    }
  }

  .more-info {
    > .link {
      text-decoration: underline;
    }

    > .arrow {
      text-decoration: none;
    }
  }
</style>
