<script lang='ts'>
  import { link } from 'svelte-routing'

  import { allCountries } from '@/config/allCountries'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { findDirtyInputs } from '@/helpers/formHelpers'
  import { _ } from '@/helpers/i18n'
  import { getTimezone } from '@/helpers/mixHelpers'
  import { notifier } from '@/helpers/notifier'

  import AvatarInput from '@/components/social/AvatarInput.svelte'

  import { EditUserProfile } from '@/definitions/langoid'

  let user: EditUserProfile = {} as EditUserProfile
  let formData: EditUserProfile = {} as EditUserProfile
  let dirtyInputs: any = {}
  const userTimezone = getTimezone()

  $: if (formData) {
    formData.timezone = userTimezone
    dirtyInputs = findDirtyInputs(user, formData)
  }

  const getData = async () => {
    const data = await fetchData('social/editProfileLoad', {})
    user = { ...data.user }
    formData = { ...user }
  }

  const handleOnSubmit = async () => {
    if (Object.keys(dirtyInputs).length > 0) {
      await fetchData('social/editProfileUpdate', dirtyInputs)
      user = { ...formData }
      dirtyInputs = {}
      notifier.success($_('profile.msgUpdated'))
    }
  }

  getData()
</script>

<div class='edit-profile-page _gap24'>
  <div class='profile-photo-wrapper'>
    <AvatarInput avatar={formData.avatar} />
  </div>
  <form class='_form' on:submit|preventDefault={handleOnSubmit}>
    <p><label>{$_('profile.status')}: <input type='text' bind:value={formData.status_text} /></label></p>
    <p><label>{$_('profile.nick')}: <input type='text' bind:value={formData.nick} /></label></p>

    <p><label>{$_('auth.name')}: <input type='text' bind:value={formData.name} /></label></p>
    <p><label class='editBirthday'>{$_('profile.birthday')}:<input type='date' bind:value={formData.birthdate} /></label></p>
    <p class='genderMaleFemale'>
      <span class='gender-title'>{$_('profile.gender')}:</span>
      <label>{$_('profile.male')} <input type='radio' value='male' bind:group={formData.gender} name='gender' /></label>
      <label>{$_('profile.female')} <input type='radio' value='female' bind:group={formData.gender} name='gender' /></label>
    </p>
    <input type='hidden' bind:value={formData.timezone} />
    <p>
      <label for='country-input'>{$_('profile.country')}:
        <select id='country-input' bind:value={formData.country} name='country'>
          <option value=''>{$_('profile.selectCountry')}</option>
          {#each allCountries as country}
            <option value={country.id}>{country.name}</option>
          {/each}
        </select>
      </label>
    </p>
    <p><label for='bio-input'>{$_('profile.aboutMe')}</label>
      <textarea id='bio-input' class='bio-input' bind:value={formData.bio} /></p>
    <p><input disabled={Object.keys(dirtyInputs).length === 0} type='submit' value={$_('mix.save')} /></p>
  </form>
  <a class='default-button back-to-profile' href='/profile/{user.id}' use:link>{$_('profile.backToProfile')}</a>
</div>
