<script lang='ts'>
  import Svelecte from 'svelecte'
  import { onMount } from 'svelte'
  import RangeSlider from 'svelte-range-slider-pips'

  import { allCountries } from '@/config/allCountries'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { findDirtyInputs } from '@/helpers/formHelpers'
  import { _ } from '@/helpers/i18n'
  import { notifier } from '@/helpers/notifier'

  import { AllLanguagesItem, SearchUser } from '@/definitions/langoid'

  let allLanguages: AllLanguagesItem[] = []
  import('@/config/allLanguages').then((data) => {
    allLanguages = data.allLanguages
  })

  export let users: SearchUser[] | undefined = [],
    setUsers = (usersArray: SearchUser[], offset: string = '') => {},
    addUsers = (usersArray: SearchUser[], offset: string = '') => {},
    offset = '',
    num = 0
  const MIN_USER_AGE = 13
  const MAX_USER_AGE = 123
  const AGE_STEP = 1
  const MIN_LEVEL_RANGE = 1
  const MAX_LEVEL_RANGE = 1000
  const LEVEL_STEP = 1
  const MIN_POINTS_RANGE = 0
  const MAX_POINTS_RANGE = 10000
  const POINTS_STEP = 10
  const initialForm = {
    active: false,
    ageRange: [MIN_USER_AGE, MAX_USER_AGE],
    country: '',
    languages: [], // todo - set initial options based on user's languages
    levelRange: [MIN_LEVEL_RANGE, MAX_LEVEL_RANGE],
    nick: '',
    photo: false,
    pointsRange: [MIN_POINTS_RANGE, MAX_POINTS_RANGE]
  }

  let currentForm = { ...initialForm }
  let dirtyInputs: any = {}
  let formEl: any
  let countrySelection: any
  let languagesSelection: any

  const nullTimestamp = '0000-00-00 00:00:00'

  $: if (offset !== nullTimestamp) {
    getUsers(offset)
  }

  $: if (currentForm) {
    dirtyInputs = findDirtyInputs(initialForm, currentForm)
  }

  async function getUsers (offset = nullTimestamp) {
    const data = await fetchData('social/searchSearch', {
      active: dirtyInputs.active,
      age_from: dirtyInputs.ageRange?.[0],
      age_to: dirtyInputs.ageRange?.[1],
      country: dirtyInputs.country,
      languages: dirtyInputs.languages,
      level_from: dirtyInputs.levelRange?.[0],
      level_to: dirtyInputs.levelRange?.[1],
      nick: dirtyInputs.nick,
      offset,
      photo: dirtyInputs.photo,
      points_from: dirtyInputs.pointsRange?.[0],
      points_to: dirtyInputs.pointsRange?.[1]
    })
    const users2: SearchUser[] = Object.values(data.users)
    users = [...(users || []), ...users2]
    if (users.length === 0) {
      notifier.info('No users found')
    }
    offset = data.offset
    if (offset !== nullTimestamp) {
      addUsers(users, offset)
    } else {
      setUsers(users, nullTimestamp)
    }
  }

  const handleOnSubmit = () => {
    setUsers([])
    getUsers(nullTimestamp)
  }

  const handleOnReset = () => {
    formEl.reset()
    currentForm = { ...initialForm }
  }

  onMount(() => {
    if (num === 0) {
      getUsers()
    }
  })
</script>

<form bind:this={formEl} class='search-for-user' on:submit|preventDefault={handleOnSubmit}>
  <!-- <h3 class="searchHeading">{$_('social.searchForUsers')}</h3> -->
  <div class='elements-divider'>
    <p class='searchTitle'>{$_('social.languagesSpeaking')}:</p>
    <Svelecte
      inputId='languages'
      multiple
      options={allLanguages}
      placeholder={$_('social.languagesSpeaking')}
      bind:readSelection={languagesSelection}
      bind:value={currentForm.languages}
    />
  </div>
  <label class='elements-divider'>{$_('profile.nick')}:
    <input placeholder={$_('social.addText')} type='text' bind:value={currentForm.nick} />
  </label>

  <div class='elements-divider'>{$_('social.ageRange')}: <span class='searchTitle'>{currentForm.ageRange[0]}
    - {currentForm.ageRange[1]}</span>
    <RangeSlider
      first='label'
      float
      last='label'
      max={MAX_USER_AGE}
      min={MIN_USER_AGE}
      pips
      step={AGE_STEP}
      bind:values={currentForm.ageRange}
    />
  </div>
  <div class='picture-and-status'>
    <label class='check-button'>
      <input type='checkbox' bind:checked={currentForm.photo} />
      <span class='checkbox' />
      {$_('profile.withPhotoOnly')}
    </label>
    <label class='check-button'>
      <input type='checkbox' bind:checked={currentForm.active} />
      <span class='checkbox' />
      {$_('profile.onlyOnline')}
    </label>
  </div>
  <div class='elements-divider'>{$_('profile.country')}:
    <Svelecte
      inputId='country'
      options={allCountries}
      placeholder={$_('profile.selectCountry')}
      bind:readSelection={countrySelection}
      bind:value={currentForm.country}
    />
  </div>

  <div class='elements-divider'>{$_('mix.points')}: <span class='searchTitle'>{currentForm.pointsRange[0]}
    - {currentForm.pointsRange[1]}</span>

    <RangeSlider
      first='label'
      float
      last='label'
      max={MAX_POINTS_RANGE}
      min={MIN_POINTS_RANGE}
      pips
      step={POINTS_STEP}
      bind:values={currentForm.pointsRange}
    />
  </div>

  <div class='elements-divider'>{$_('mix.level')}: <span class='searchTitle'>{currentForm.levelRange[0]}
    - {currentForm.levelRange[1]}</span>
    <RangeSlider
      first='label'
      float
      last='label'
      max={MAX_LEVEL_RANGE}
      min={MIN_LEVEL_RANGE}
      pips
      step={LEVEL_STEP}
      bind:values={currentForm.levelRange}
    />
  </div>
  <div class='buttons-wrapper'>
    <input class='primaryButton' type='submit' value={$_('profile.search')} />
    <input
      class='secondaryButton'
      disabled={Object.keys(dirtyInputs).length === 0}
      type='reset'
      value={$_('mix.reset')}
      on:click={handleOnReset}
    />
  </div>
</form>

<style lang='scss'>
  .buttons-wrapper {
    display: flex;
    gap: 0.8rem;

    > .primaryButton,
    > .secondaryButton {
      width: 100%;
    }
  }

  .elements-divider {
    margin-bottom: 1.2rem;
    padding: 1.2rem;
    border-radius: 0.6rem;
  }

  .picture-and-status {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding: 1.2rem;
  }

</style>
