<script lang='ts'>
  import { _ } from '@/helpers/i18n'

  import Icon from '@/components/icons/Icon.svelte'

  import { TaskType } from '@/definitions/langoid'

  export let selected: string,
    taskCounts: Record<TaskType, number> = {
      listen: 0,
      read: 0,
      story: 0,
      translate: 0
    }
</script>

<div class='_tabs-wrapper'>
  <button
    class:_transparent-gray={selected !== 'story'}
    tabindex='0'
    type='button'
    on:click={() => {selected = 'story'}}
    on:keypress={() => {}}
  >
    <span class='_mobileOnly'><Icon icon='NotePencil' weight='fill' /></span>
    <span class='_desktopOnly'>{$_('tasks.story')}</span> {taskCounts.story ? `(${taskCounts.story})` : '(0)'}
  </button>
  <button
    class:_transparent-gray={selected !== 'translate'}
    tabindex='0'
    type='button'
    on:click={() => {selected = 'translate'}}
    on:keypress={() => {}}
  >
    <span class='_mobileOnly'><Icon icon='Translate' weight='fill' /></span>
    <span class='_desktopOnly'>{$_('mix.translation')}</span> {taskCounts.translate ? `(${taskCounts.translate})` : '(0)'}
  </button>
  <button
    class:_transparent-gray={selected !== 'read'}
    tabindex='0'
    type='button'
    on:click={() => {selected = 'read'}}
    on:keypress={() => {}}
  >
    <span class='_mobileOnly'><Icon icon='UserSound' weight='fill' /></span>
    <span class='_desktopOnly'>{$_('tasks.read')}</span> {taskCounts.read ? `(${taskCounts.read})` : '(0)'}
  </button>
  <button
    class:_transparent-gray={selected !== 'listen'}
    tabindex='0'
    type='button'
    on:click={() => {selected = 'listen'}}
    on:keypress={() => {}}
  >
    <span class='_mobileOnly'><Icon icon='Headphones' weight='fill' /></span>
    <span class='_desktopOnly'>{$_('tasks.listen')}</span> {taskCounts.listen ? `(${taskCounts.listen})` : '(0)'}
  </button>
</div>

<style lang='scss'>
  @media (max-width: 768px) {
    ._tabs-wrapper {
      padding: 0 0.8rem 0 0.8rem;
    }
  }
</style>
