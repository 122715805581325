<script lang='ts'>
  /*
   * if you pass id it will be linked to profile
   * if you pass points it will be shown
   */
  import { link } from 'svelte-routing'

  import { TWO_DIGIT_YEAR } from '@/config/constants'

  import Flag from '@/components/forum/Flag.svelte'
  import Avatar from '@/components/social/Avatar.svelte'

  import { _ } from '@/libs/i18n'

  export let user: any = {},
    avatarVariant: 'smallAvatar' | 'menuAvatar' | 'bigAvatar' = 'smallAvatar',
    nameVariant: 'nick' | 'fullName' | 'noName' = 'nick',
    metaDataVariant: 'bottom' | 'right' = 'bottom',
    dateVariant: 'noDate' | 'withDate' = 'noDate',
    pointsVariant: 'noPoints' | 'withPoints' = 'noPoints',
    noLink = false,
    withFlag = false,
    date = ' '

  let userName = ''

  $: if (user) {
    if (nameVariant === 'noName') {
      userName = ''
    } else {
      userName = nameVariant === 'fullName' ? user.name || '' : user.nick?.split(' ')[0] || user.name?.split(' ')[0]
    }
  }
  let parsedDate: Record<string, string> = {}
  $:if (date) {
    const [datePart, timePart]: string[] = date.split(' ')
    const [year, month, day] = datePart.split('-')
    const [hour, minute] = timePart.split(':')
    parsedDate = { day, hour, minute, month, year: year.substring(TWO_DIGIT_YEAR) }
  }
</script>
{#if user}
  <div class={`user-preview _row -${metaDataVariant}`}>
    <div class={`avatar-wrapper -${avatarVariant}`}>
      <Avatar {avatarVariant} img={user.avatar} userName={user.name} />
      <div class='level' title={$_('level.level')}>{user.level} </div>
    </div>
    {#if userName}
      <div class={`meta-data _row -${metaDataVariant}`}>
        {#if !noLink}
          <a href='/profile/{user.id}' rel='nofollow' on:click|stopPropagation use:link>{userName}</a>
        {:else}
          <div>{userName}</div>
        {/if}
        {#if pointsVariant === 'withPoints'}
          <p>•</p>
          <span class='points'>{Math.round(user.points)} </span>
        {/if}
        {#if dateVariant === 'withDate' && parsedDate.year}
          <p>•</p>
          <div class='date' title='{parsedDate.hour}:{parsedDate.minute}'>
            {parsedDate.year}/{parsedDate.month}/{parsedDate.day}
          </div>
        {/if}
        {#if withFlag}
          <p>•</p>
          <Flag id={user.id_lang_native} variant='small' />
        {/if}
      </div>
    {/if}
  </div>
{/if}

<style lang='scss'>
  .user-preview {
    line-height: 1;
    text-align: center;

    &.-bottom {
      flex-direction: column;
    }

    &.-right {
      flex-direction: row;
    }
  }

  .avatar-wrapper {
    position: relative;

    > .level {
      position: absolute;
      font-weight: bold;
      font-size: 1.2rem;
      text-align: center;
      color: var(--inverted-text-color);
      background-color: var(--Accent-Medium);
      border-radius: 50%;
      outline: 0.1rem solid var(--inverted-text-color);
    }

    &.-smallAvatar {
      > .level {
        top: -0.5rem;
        right: -0.8rem;
        min-width: 1.2rem;
        padding: 0.2rem;
        font-size: 1rem;
        line-height: 1;
        border-radius: 0.2rem;
      }
    }

    &.-bigAvatar {
      > .level {
        bottom: 0rem;
        left: 0rem;
        min-width: 2rem;
        height: 2rem;
        line-height: 1.6;
      }
    }

    &.-menuAvatar {
      > .level {
        bottom: -0.4rem;
        left: -0.4rem;
        min-width: 2rem;
        height: 2rem;
        line-height: 1.6;
      }
    }
  }

  .meta-data {
    min-width: 7.2rem;
    color: var(--text-primary-color);

    > a {
      font: var(--Semibold-200);
      color: var(--text-primary-color);

      &:hover {
        color: var(--Primary-Medium);
      }
    }

    > .date {
      font: var(--Regular-200);
    }

    &.-bottom {
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    .user-preview {
      font-size: 1.2rem;
    }

    .avatar-wrapper {
      &.-bigAvatar {
        > .level {
          bottom: 0;
          left: 0;
        }
      }
    }
  }
</style>
