<script lang='ts'>
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'

  import Overlay from '@/components/ui/Overlay.svelte'

  import Avatar from './Avatar.svelte'

  export let avatar: any
  let previewImage: string | undefined
  let fileInput: any
  let imageExt: string

  const uploadAvatar = async (action: string) => {
    if (action === 'delete') {
      await fetchData('social/editProfileUpdateAvatar', {
        avatar,
        delete: 'yes',
        ext: avatar.split('.').pop()
      })
      previewImage = undefined
      avatar = undefined
    } else if (action === 'upload') {
      const data = await fetchData('social/editProfileUpdateAvatar', { avatar: previewImage, delete: 'no', ext: imageExt })
      avatar = data.path
    }
  }

  const onFileSelected = (e: any) => {
    const image = e.target.files[0]
    imageExt = image.type.split('/')[1]
    const reader = new FileReader()
    reader.readAsDataURL(image)
    reader.onload = (e: any) => {
      previewImage = e.target.result as string
    }
  }

  const chooseImage = () => {
    fileInput.click()
  }
</script>

<label for='avatar-input'>{$_('profile.profilePhoto')}:</label>
{#if avatar}
  <div class='avatar-wrapper'>
    <Avatar avatarVariant='bigAvatar' img={avatar} />
    <button class='_warning-btn' type='button' on:click={() => uploadAvatar('delete')}>
      {$_('profile.removePhoto')}
    </button>
  </div>
{:else}
  {#if previewImage}
    <Overlay>
      <div class='avatar-wrapper'>
        <img class='previewImage' alt='avatar' src={previewImage} />
        <button class='_maxwidth-btn' type='button' on:click={() => uploadAvatar('upload')}>
          {$_('profile.uploadImage')}
        </button>
        <button class='_maxwidth-btn _warning-btn' type='button' on:click={() => (previewImage = undefined)}>
          {$_('profile.deleteImage')}
        </button>
      </div>
    </Overlay>
  {:else}
    <div class='avatar-wrapper'>
      <input bind:this={fileInput} id='avatar-input' accept='.jpg, .jpeg, .png' type='file' on:change={onFileSelected} />
      <button type='button' on:click={chooseImage}>
        {$_('profile.chooseImage')}
      </button>
    </div>
  {/if}
{/if}

<style lang='scss'>
  #avatar-input {
    display: none;
  }

  .avatar-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    align-items: flex-start;

    > .previewImage {
      display: block;
      object-fit: cover;
      width: 38.4rem;
      height: 38.4rem;
      border-radius: 0.8rem;
    }
  }
</style>
