<script lang='ts'>
  import { MIN_NUMBER_OF_USER_LANGUAGES } from '@/config/constants'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { toInt } from '@/helpers/mixHelpers'
  import { notifier } from '@/helpers/notifier'

  import Flag from '@/components/forum/Flag.svelte'
  import CloseButton from '@/components/ui/CloseButton.svelte'
  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'
  import Overlay from '@/components/ui/Overlay.svelte'

  import { UserLanguage, UserLearningLanguages } from '@/definitions/langoid'

  export let profileLanguages: number[] = [],
    editLanguages = false

  let languages: UserLanguage[] = []
  let languagesInitial: UserLanguage[] = []
  let idEdited = -1
  let native = 0
  let loaded = false
  let addLanguageForm: HTMLFormElement
  let idNewLanguage = 0 // used for adding new languages

  const getData = async () => {
    const data = await fetchData('social/editLanguagesLoad', {})
    native = parseInt(data.user.id_lang_native)
    languagesInitial = data.languages
    languages = [...languagesInitial]
    loaded = true
  }

  getData()

  function saveLanguage () {
    const formData = new FormData(addLanguageForm)
    const data = {
      language: formData.get('language'),
      level: formData.get('level')
    }
    const action = idEdited === 0 ? 'add' : 'edit'
    updateLanguage(Number(data.language), (data?.level || '').toString(), action)
  }

  async function updateLanguage (language: number, level: string, action: string) {
    const data = await fetchData('social/editLanguagesUpdate', { action, language })
    languages = data.languages
    profileLanguages = languages.map(el => el.id_language) // update list on the profile page
    notifier.success($_('profile.userLanguagesUpdated'))
    languagesInitial = languages
    idEdited = -1
  }

  const deleteLanguage = (id: number) => {
    if (confirm($_('profile.userLanguagesDeleteConfirm'))) {
      updateLanguage(id, '??', 'delete')
    }
  }

  const getLangField = (idEdited: number, languages: UserLearningLanguages[], field: 'id_language' | 'max_level'): string | number => {
    if (idEdited > 0) {
      const currentLanguge = languages.find(el => el.id_language === idEdited)
      return currentLanguge?.[field] || ''
    } else {
      return ''
    }
  }
</script>

<div class='languages-wrapper'>
  <h2>{$_('profile.yourLanguages')}</h2>
  <p class='table-head'>
    <span>{$_('mix.language')}</span>
    <span>{$_('profile.status')}</span>
  </p>
  {#if loaded}
    {#if native}
      <div class='each-language -native'>
        <Flag id={native} />
        {$_('mix.native')}
      </div>
    {/if}
    <CloseButton closeVariant='absolute' onClick={() => {editLanguages = false}} />
    <form class='languages-form' on:submit|preventDefault={saveLanguage}>
      {#each languages as language (language.id_language)}
        {#if native !== language.id_language}
          <hr />
          <div class='each-language'>
            <span class='name'>
              <Flag id={language.id_language} /> {language.max_level}
            </span>
            <span class='level'>
              {language.confirmed}
              <!-- 'none','native','learn','claim','proof' -->
            </span>
            <span class='lang-buttons'>
              {#if !['learn', 'proof'].includes(language.confirmed)}
                <button class='_secondary-btn' type='button' on:click={() => { idEdited = language.id_language }}>{$_('mix.edit')}</button>
              {/if}
              {#if !['learn', 'proof'].includes(language.confirmed)}
                <button
                  class='_secondary-btn'
                  disabled={languages.length <= MIN_NUMBER_OF_USER_LANGUAGES}
                  type='button'
                  on:click={() => deleteLanguage(language.id_language)}
                >{$_('mix.delete')}</button>
              {/if}
              {#if language.shown}
                <button class='_secondary-btn' type='button' on:click={() => updateLanguage(language.id_language, '??', 'hide')}>{$_('mix.hide')}</button>
              {:else}
                <button class='_secondary-btn' type='button' on:click={() => updateLanguage(language.id_language, '??', 'show')}>{$_('mix.show')}</button>
              {/if}
            </span>
          </div>
        {/if}
      {/each}
    </form>
  {/if}
  <button type='button' on:click={() => { idEdited = 0 }}>{$_('forum.addLanguage')}</button>
  {#if idEdited >= 0}
    <Overlay onClick={() => { idEdited = -1 }} variant='overflow'>
      <form bind:this={addLanguageForm} class='add-new-form _form' action='' on:submit|preventDefault={saveLanguage}>
        <CloseButton closeVariant='absolute' onClick={() => { idEdited = -1 }} />
        <p>
          {#if idEdited > 0}
            <label for='edit-lang'>{$_('mix.language')}</label>
            <Flag id={idEdited} />
            <input type='hidden' value={idEdited} name='language' />
          {:else}
            <label for='edit-lang'>{$_('mix.language')}</label>
            <LanguageSelect
              label='edit-lang'
              onChange={(lang) => {
                idNewLanguage = lang
                return true
              }}
              placeholder={$_('mix.language')}
              selectedLanguage={toInt(getLangField(idEdited, languages, 'id_language'))}
              type='all'
            />
            <input type='hidden' value={idNewLanguage} name='language' />
          {/if}
        </p>
        <p>
          <label for='level'>{$_('profile.level')}</label>
          <select
            id='level'
            value={getLangField(idEdited, languages, 'max_level')}
            name='level'
          >
            <option value=''>{$_('profile.level')}</option>
            <option value='A0'>{$_('profile.absoluteBeginner')}</option>
            <option value='A1'>A1</option>
            <option value='A2'>A2</option>
            <option value='B1'>B1</option>
            <option value='B2'>B2</option>
            <option value='C1'>C1</option>
            <option value='C2'>{$_('profile.c2orNative')}</option>
            <option value='?'>{$_('profile.iDontKnow')}</option>
          </select>
        </p>

        <input type='submit' value={$_('mix.save')} />
      </form>
    </Overlay>
  {/if}
</div>

<style lang='scss'>
  .languages-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  .table-head {
    display: grid;
    grid-template-columns: 9.6rem 1fr 1fr;
    align-items: center;
    padding: 0.8rem 0;
    font-weight: bold;
    border-bottom: 0.2rem solid var(--Gray-Medium);
  }

  .lang-buttons {
    display: flex;
    gap: 1.6rem;
  }

  .each-language {
    display: grid;
    grid-template-columns: 9.6rem 1fr 1fr;
    align-items: center;
    padding: 0.8rem 0;

    &.-native {
      font-weight: bold;
    }
  }

  .add-new-form {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }
</style>
