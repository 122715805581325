<script lang='ts'>
  import { _ } from '@/helpers/i18n'

  import Flag from '@/components/forum/Flag.svelte'
  import UserAvatar from '@/components/social/UserAvatar.svelte'
  import Overlay from '@/components/ui/Overlay.svelte'

  import UserProfile from './UserProfile.svelte'

  import { SearchUser } from '@/definitions/langoid'

  export let user: SearchUser,
    variant = '',
    avatarVariant: 'smallAvatar' | 'bigAvatar' = 'smallAvatar',
    pointsVariant: 'noPoints' | 'withPoints' = 'noPoints'

  const isLandscape = variant === 'landscape'
  const myFriends = variant === 'myFriends'
  let showProfile = false
</script>

<div
  class='teaser-info'
  class:-landscape={isLandscape}
  class:-myFriends={myFriends}
  role='button'
  tabindex='0'
  on:click={() => { showProfile = true }}
  on:keypress={() => {}}
>
  <UserAvatar {avatarVariant} metaDataVariant='right' noLink {pointsVariant} {user} />
  {#if isLandscape}
    <div class='teaser-block'>
      <span class='speaking'>{$_('mix.native')}: </span>
      <Flag id={user.id_lang_interface} variant='small' />
    </div>
    <div class='teaser-learning-lang'>
      {#if user.languages.length > 0}
        <span class='learning'>{$_('mix.learning')}: </span>
        {#each user.languages.filter(el => el.id_language !== user.id_lang_interface) as lang}
          <Flag id={lang.id_language} variant='small' />
        {/each}
      {/if}
    </div>
  {/if}
</div>

{#if showProfile}
  <Overlay onClick={() => { showProfile = false }}>
    <UserProfile close={() => { showProfile = false }} {user} />
  </Overlay>
{/if}

<style lang='scss'>
  .teaser-learning-lang {
    > .learning {
      display: none;
      padding-left: 0.4rem;
    }
  }

  .teaser-info {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    cursor: pointer;

    /* used on profile page */
    &.-myFriends {
      flex-direction: row;
      justify-content: center;
      width: auto;
      padding: 2rem 0.4rem;
      border-radius: 0.8rem;
    }

    /* used on search page */
    &.-landscape {
      display: grid;
      grid-template-columns: 1fr 4rem 1fr;
      padding: 0.8rem;
      border-radius: 0.8rem;
    }

    &:hover {
      background-color: var(--hover-background);
    }
  }

  .teaser-block > .speaking {
    display: none;
  }
</style>
