<script lang='ts'>
  import { userStorredConfig } from '@/store'

  interface MyWindow extends Window {
    [key: string]: any;
  }

  import { onDestroy } from 'svelte'

  import { getIsoFromId } from '@/helpers/apiCall'
  import { dateString } from '@/helpers/dateHelpers'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { notifier } from '@/helpers/notifier'

  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import LanguageSelect from '@/components/ui/LanguageSelect.svelte'
  import PageInfo from '@/components/ui/PageInfo.svelte'

  import { FetchCatchError } from '@/definitions/langoid'

  let language: number = $userStorredConfig.id_lang_learning
  let period = 'all'
  const offset = -30
  let start = dateString('day', offset)
  let end = dateString('day')
  const style = getComputedStyle(document.body)
  let userId = 0
  const iso = getIsoFromId($userStorredConfig.id_lang_learning)
  const breads = [
    { mobileUrl: `/${iso}`, text: '', url: `/${iso}` },
    { text: 'stats.title' }
  ]

  const unsubscribe = userStorredConfig.subscribe((user) => {
    if (user?.id && userId === 0) {
      userId = user.id
      loadStats(language, period, start, end)
    }
  })

  function loadStats (language: number, period: string, start: string, end: string): void {
    fetchData('social/stats', {
      end,
      language,
      period,
      start
    })
      .then((data: any) => {
        showChart(data.time, 'timeChart')
        showChart(data.points, 'pointsChart')
        showChart(data.tests, 'testsChart')
        const dataCards = {
          ...data.new,
          result: {}
        }
        if (data.new.result.new.length > 0) {
          dataCards.result.new = data.new.result.new
        }
        if (data.leveled.result.leveled.length > 0) {
          dataCards.result.leveled = data.leveled.result.leveled
        }
        if (data.learned.result.learned.length > 0) {
          dataCards.result.learned = data.learned.result.learned
        }
        showChart(dataCards, 'cardsChart')
      })
      .catch((error: FetchCatchError) => {
        notifier.warning(error)
      })
  }

  $:if (period || start || end || language) {
    if (userId) loadStats(language, period, start, end)
  }

  async function showChart (data: { points: string[], result: Record<number, Array<{ date: string; num: number }>> }, element: string) {
    const colors: Record<string, string> = {
      grid: style.getPropertyValue('--text-background'),
      learned: style.getPropertyValue('--know'),
      leveled: style.getPropertyValue('--good'),
      new: style.getPropertyValue('--seen'),
      points: style.getPropertyValue('--Accent-Light'),
      tests: style.getPropertyValue('--Error-Medium'),
      time: style.getPropertyValue('--Primary-Ligth')
    }
    const datasets = []
    for (const i in data.result) {
      const dataResult = data.result[i]
      const result: number[] = []
      const tmp: Record<string, number> = {}
      if (dataResult) {
        dataResult.forEach((e: { date: string; num: number }) => {
          if (tmp[e.date]) {
            tmp[e.date] += e.num
          } else tmp[e.date] = e.num
        })

        data.points.forEach((item: string) => {
          result.push(tmp[item] ? tmp[item] : 0)
        })
        datasets.push({
          borderColor: colors[i] ? colors[i] : 'rgb(75, 192, 192)',
          data: result,
          fill: false,
          label: i,
          lineTension: 0.1
        })
      }
    }

    const canvasEl: HTMLCanvasElement = document.getElementById(element) as HTMLCanvasElement
    const ctx = canvasEl.getContext('2d')
    if (ctx) {
      const myWindow = window as MyWindow
      const windowKey: string = `My${element}`
      const { Chart } = await import('@/helpers/chart')
      if (myWindow[windowKey]) {
        myWindow[windowKey].destroy()
      }
      myWindow[windowKey] = new Chart(ctx, {
        data: {
          datasets,
          labels: data.points
        },
        options: {
          scales: {
            x: { grid: { color: colors.grid } },
            y: { grid: { color: colors.grid } }
          }
        },
        type: 'line'
      })
    }
  }

  const changeLang = (lang: number) => {
    language = lang
    return true
  }

  onDestroy(() => {unsubscribe()})
</script>
<Breadcrumb {breads}>
  <h2 class='heading-mobile'>{$_('stats.title')}</h2>
  <div slot='pageHelperIcons'>
    <PageInfo kebabItem pageName='stats' />
  </div>
</Breadcrumb>
{#if userId}
  <div class='stats-page _gap24 _form'>
    <div class='stats-filters'>
      <LanguageSelect onChange={changeLang} placeholder={$_('stats.allLanguages')} bind:selectedLanguage={language} />
      <div class='_tabs-wrapper'>
        <button class:_tertiary-btn={period !== 'all'} type='button' on:click={() => {period = 'all'}}>{$_('mix.all')}</button>
        <button class:_tertiary-btn={period !== 'month'} type='button' on:click={() => {period = 'month'}}>{$_('stats.month')}</button>
        <button class:_tertiary-btn={period !== 'year'} type='button' on:click={() => {period = 'year'}}>{$_('stats.year')}</button>
        <button class:_tertiary-btn={period !== 'custom'} type='button' on:click={() => {period = 'custom'}}>{$_('stats.custom')}</button>
      </div>
      {#if period === 'custom'}
        <div class='start-end-date'>
          <div class='start-date'>
            <label for='date-start'>{$_('stats.start')}</label>
            <input id='date-start' type='date' bind:value={start} />
          </div>
          <div class='end-date'>
            <label for='date-end'>{$_('stats.end')}</label>
            <input id='date-end' type='date' bind:value={end} />
          </div>
        </div>
      {/if}
    </div>
    <div class='charts-wrapper'>
      <div class='chart'>
        <h2 class='label'>{$_('stats.points')}</h2>
        <canvas id='pointsChart' />
      </div>
      <div class='chart'>
        <h2 class='label'>{$_('stats.tests')}</h2>
        <canvas id='testsChart' />
      </div>
      <div class='chart'>
        <h2 class='label'>{$_('stats.cards')}</h2>
        <div class='cards-legend'>
          <div class='item -learned'>{$_('stats.learned')}</div>
          <div class='item -leveled'>{$_('stats.leveled')}</div>
          <div class='item -new'>{$_('stats.new')}</div>
        </div>
        <canvas id='cardsChart' />
      </div>
      <div class='chart'>
        <h2 class='label'>{$_('stats.timeSpent')}</h2>
        <canvas id='timeChart' />
      </div>
    </div>
  </div>
{/if}

<style lang='scss'>
  .stats-filters {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    > :global(.dropdown-wrapper) {
      position: absolute;
      top: 4rem;
      right: 2.4rem;
      width: fit-content;
    }
  }

  .start-end-date {
    display: flex;
    gap: 2.4rem;
    align-items: center;
  }

  .charts-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    max-width: 102.4rem;

    > .chart {
      max-width: 100%;

      > canvas {
        width: 100%;
        height: 25.6rem;
      }
    }
  }

  .cards-legend {
    > .item {
      width: 100%;
      padding: 1rem;
      color: var(--Base-White);

      &.-learned {
        background-color: var(--know);
      }

      &.-leveled {
        background-color: var(--good);
      }

      &.-new {
        background-color: var(--seen);
      }
    }
  }

  @media(max-width: 992px) {
    .stats-filters {
      > :global(.dropdown-wrapper) {
        position: static;
        width: 100%;
      }
    }
  }
</style>
