<script lang='ts'>
  import { ChartConfiguration } from 'chart.js'
  import { onMount } from 'svelte'

  import { TESTS } from '@/config/constants'

  import { fetchData } from '@/helpers/fetchHelpers'

  import Table from '@/components/ui/Table.svelte'

  import { _ } from '@/libs/i18n'

  let period = 'month'
  const offset = 0
  const start = new Date(Date.now() + offset * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
  const end = new Date().toISOString().split('T')[0]
  let chartLeveled: HTMLCanvasElement
  let chartRetention: HTMLCanvasElement
  let chartInstanceLeveled: any
  let chartInstanceRetention: any
  let rows: any[] = []
  let tableHeaders: any[] = []
  let testType: number = TESTS.TRANSLATE_WRITTEN

  onMount(loadData)
  /**
   *     distinct_up,
   *     distinct_down,
   *     total_up,
   *     total_down,
   *     100*(1-total_down/(total_up+total_down)) AS retention,
   *     100*(1-distinct_down/(distinct_up+distinct_down)) AS retention_distinct,
   *     distinct_up - distinct_down AS distinct_diff,
   *     total_up - total_down AS total_diff
   */
  type LeveledData = {
    distinct_diff: number,
    distinct_down: number
    distinct_up: number,
    query_date: string,
    retention: number,
    retention_distinct: number,
    total_diff: number,
    total_down: number,
    total_up: number
  }[]

  async function showChart (data: LeveledData) {
    const { Chart } = await import('@/helpers/chart')
    if (chartInstanceLeveled) chartInstanceLeveled.destroy() // clear the canvas
    if (chartInstanceRetention) chartInstanceRetention.destroy() // clear the canvas
    const options = {
      plugins: {
        legend: {
          display: true,
          position: 'top' as const
        }
      },
      scales: {},
    }
    const type = 'line' as const
    const labels = data.map(item => item.query_date)
    const style = getComputedStyle(document.body)
    const colors = [
      style.getPropertyValue('--Green-Medium'),
      style.getPropertyValue('--Cyan-Medium'),
      style.getPropertyValue('--Rose-Medium'),
      style.getPropertyValue('--Yellow-Medium'),
      style.getPropertyValue('--Error-Medium'),
      style.getPropertyValue('--Success-Medium'),
      style.getPropertyValue('--Accent-Medium'),
      style.getPropertyValue('--Primary-Medium')
    ]
    const itemData = (color: string) => {
      return {
        backgroundColor: color,
        borderColor: color,
        fill: false,
        pointBackgroundColor: color,
        tension: 0.1
      }
    }
    const datasetsLeveled = [
      { ...itemData(colors[0]), data: data.map(item => item.distinct_down), hidden: true, label: 'Distinct down' },
      { ...itemData(colors[1]), data: data.map(item => item.distinct_up), hidden: true, label: 'Distinct up' },
      { ...itemData(colors[2]), data: data.map(item => item.distinct_diff), label: 'Distinct diff' }
    ]
    const datasetsRetention = [
      { ...itemData(colors[3]), data: data.map(item => item.retention), hidden: true, label: 'Retention' },
      { ...itemData(colors[4]), data: data.map(item => item.retention_distinct), label: 'Distinct retention' }
    ]
    const chartSetupLeveled: ChartConfiguration<'line'> = { data: { datasets: datasetsLeveled, labels }, options, type }
    const chartSetupRetention: ChartConfiguration<'line'> = { data: { datasets: datasetsRetention, labels }, options, type }
    chartInstanceLeveled = new Chart(chartLeveled, chartSetupLeveled)
    chartInstanceRetention = new Chart(chartRetention, chartSetupRetention)
  }

  async function loadData () {
    const res = await fetchData('social/statsLeveled', {
      end,
      offset,
      period,
      start,
      testType
    })

    if (res) {
      const results = res.results.map(d => ({
        /* eslint-disable  sort-keys */
        date: d.query_date,
        up: d.distinct_up,
        down: d.distinct_down,
        diff: d.distinct_diff,
        retention: d.retention_distinct
        /* eslint-enable  sort-keys */
      }))
      showChart(res.results)
      rows = results
      tableHeaders = Object.keys(results[0] || {})
    }
  }

  $:if (testType) {
    loadData()
  }
</script>
<p style:display='none'>
    <span class='custom-radios-square -userStats'>
      <label><input type='radio' value='month' bind:group={period} /><span>{$_('stats.month')}</span></label>
      <label><input type='radio' value='year' bind:group={period} /><span>{$_('stats.year')}</span></label>
      <label><input type='radio' value='all' bind:group={period} /><span>{$_('mix.all')}</span></label>
      <label><input type='radio' value='custom' bind:group={period} /><span>{$_('stats.custom')}</span></label>
    </span>
</p>
<div class='select-list'>
  <select bind:value={testType}>
    <option value={TESTS.TRANSLATE_WRITTEN}>{$_('levelPage.testsWords')}</option>
    <option value={TESTS.TRANSLATE_SENTENCE}>{$_('levelPage.testsSentences')}</option>
    <option value={TESTS.AUDIO_SENTENCE}>{$_('levelPage.testsAudioSentences')}</option>
    <option value={TESTS.CUSTOM_WORD}>{$_('levelPage.myWords')}</option>
    <option value={TESTS.CUSTOM_SENTENCE}>{$_('levelPage.mySentences')}</option>
  </select>
</div>
<h2>{$_('stats.leveled')}</h2>
<canvas bind:this={chartLeveled} height='400' width='400' />
<canvas bind:this={chartRetention} height='400' width='400' />

{#if tableHeaders.length}
  <Table headers={tableHeaders} {rows} />
{/if}
